import { useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { gsap } from 'gsap'

import Context from '../Context'

const Menu = () => {
  const navigate = useNavigate()

  const { authenticated, logout } = useContext(Context)

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  const openMenu = () => {
    document.getElementById('menu')?.classList.add('open')

    gsap.to('#menu', 0.65, { backgroundColor: 'rgba(11, 33, 55, .95)' })
    gsap.to('#menu ul', 0.5, { height: 350 })
    gsap.set('#menu', { height: '100%' })
  }

  const closeMenu = () => {
    const menuElement: any = document.getElementById('menu')

    menuElement.classList.remove('open')

    gsap.to('#menu', 0.65, {
      backgroundColor: 'rgba(11, 33, 55, 0)',
      onComplete: () => {
        menuElement.style.height = 0
      },
    })
    // gsap.set('#menu', { height: 0, delay: 0.65 });
    gsap.to('#menu ul', 0.25, { height: 0 })
  }

  return (
    <div id='menu'>
      <div>
        <span onClick={openMenu} className='open toggle'>
          Menu
        </span>
        <span onClick={closeMenu} className='close toggle'>
          Close
        </span>
        <ul>
          <li>
            <Link to='/login'>Investor Relations</Link>
          </li>
          {authenticated && (
            <li className='px-2 py-3 pointer' onClick={handleLogout}>
              Logout
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Menu
