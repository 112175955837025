import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import api from '../services/api'

import emailValidator from '../helpers/emailValidator'

import Menu from '../components/Menu'
import Header from '../components/Header'
import Footer from '../components/Footer'

const LostPassword = () => {
  const { state } = useLocation()

  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [formValues, setFormValues] = useState<any>({})
  const [validation, setValidation] = useState<any>({})
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)

  useEffect(() => {
    const defaultMessage = `Enter your email address and we'll send you a link to reset your password.`
    const legacyMessage = `Legacy users must reset their password. Please confirm your email.`

    if (!state) {
      setMessage(defaultMessage)
      return
    }

    ;(state as any).isLegacy ? setMessage(legacyMessage) : setMessage(defaultMessage)
  }, [state])

  const handleFormChange = (event: any) => {
    setFormValues((previousState: any) => ({ ...previousState, [event.target.name]: event.target.value }))
  }

  const handleSubmitForm = async (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)

    const validation = {
      email: formValues.email.trim() === '',
      emailValid: !emailValidator(formValues.email.trim().toLowerCase()),
    }

    setValidation(validation)
    if (Object.values(validation).some((value) => value === true)) {
      setLoading(false)
      return
    }

    try {
      await api.forgotPassword({ email: formValues.email.trim() })
      setFormSubmitted(true)
    } catch (error: any) {
      console.error(error)
      setFailed(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Menu />
      <Header />
      <div className='flex flex-col items-center bg-white p-12 md:pb-40'>
        <div className='mx-1 max-w-2xl w-full'>
          {!formSubmitted && (
            <>
              <p className='pb-8 text-center'>{message}</p>
              <form className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Email</label>
                  <input onChange={handleFormChange} name='email'></input>
                </div>
                {validation.emailValid && !validation.email && (
                  <p className='lg:pl-24 text-red-600'>Please enter a valid email</p>
                )}
                {validation.email && <p className='lg:pl-24 text-red-600'>This field is required</p>}
                <div className='flex flex-col justify-end mt-4 lg:flex-row'>
                  <button
                    onClick={handleSubmitForm}
                    type='submit'
                    className={`button font-semibold w-24 mt-4 lg:mt-0 ${loading ? 'opacity-70 cursor-default' : ''}`}
                  >
                    Send
                  </button>
                </div>
                {failed && (
                  <p className='mt-2 text-red-600 font-bold lg:pl-24'>
                    Unable to send a password reset link at this time.
                  </p>
                )}
              </form>
            </>
          )}
          {formSubmitted && (
            <p className='pb-40 text-center'>Please check your email for a link to reset your password.</p>
          )}

          <div className='mt-8 text-center'>
            <a className='font-semibold underline' href='mailto:Investorrelations@loancore.com'>
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default LostPassword
