import { useCallback, useState } from 'react'

import api from '../services/api'

import { LoginRequest } from '../types/types'

export default function useAuthentication() {
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [token, setToken] = useState<string>()
  const [user, setUser] = useState<any>()

  const login = useCallback(
    async (loginRequest: LoginRequest) => {
      const loginResponse = await api.login(loginRequest)

      setToken(loginResponse.jwt)
      setUser(loginResponse.user)
      setAuthenticated(true)

      return loginResponse.user
    },
    [setAuthenticated, setToken, setUser]
  )

  const logout = () => {
    localStorage.clear()
    setAuthenticated(false)
    setToken('')
    setUser({})
  }

  return {
    authenticated,
    login,
    token,
    setToken,
    user,
    logout,
  }
}
