import React, { useEffect } from 'react'

import { HashRouter, Route, Routes } from 'react-router-dom'
import Context from './Context'

import useAuthentication from './hooks/useAuthentication'

import Dashboard from './pages/Dashboard'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import LostPassword from './pages/LostPassword'

import './styles/App.scss'
import ResetPassword from './pages/ResetPassword'

function App() {
  const { authenticated, login, logout, token } = useAuthentication()

  const state = {
    authenticated,
    login,
    token,
    logout,
  }

  return (
    <div className='app-container'>
      <Context.Provider value={state}>
        <HashRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/register' element={<Register />} />
            <Route path='/password/lost' element={<LostPassword />} />
            <Route path='/password/reset' element={<ResetPassword />} />
          </Routes>
        </HashRouter>
      </Context.Provider>
    </div>
  )
}

export default App
