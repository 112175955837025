import { useCallback, useContext, useEffect, useState } from 'react'

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import Context from '../Context'
import api from '../services/api'

import { Announcement } from '../types/types'

const Announcements = () => {
  const { token } = useContext(Context)

  const [announcements, setAnnouncements] = useState<Array<JSX.Element>>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleGetAnnoucements = useCallback(async () => {
    setLoading(true)

    try {
      if (!token) throw new Error('No authentication token!')

      const nextAnnouncements: Array<JSX.Element> = []
      const announcementsResponse = await api.getAnnouncements(token)

      announcementsResponse.forEach((announcement: Announcement, i: number) => {
        announcement.date = new Date(announcement.date)
        nextAnnouncements.push(
          <div className='mb-16' key={i}>
            <p className='text-xl font-bold'>{announcement.date.toDateString()}</p>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{announcement.content}</ReactMarkdown>
            <hr className='mt-16' />
          </div>
        )
      })

      setAnnouncements(nextAnnouncements)
    } catch (error) {
      console.error('Unable to get announcements')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [token])

  useEffect(() => {
    handleGetAnnoucements()
  }, [handleGetAnnoucements])

  return (
    <>
      {loading && <div className='text-center mt-4'>Loading...</div>}
      <div className='flex justify-center'>
        <div className='flex justify-center max-w-4xl'>
          <div className='mx-2 py-12 text-lg'>{announcements}</div>
        </div>
      </div>
    </>
  )
}

export default Announcements
