import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { z, ZodError } from 'zod';

import Context from '../Context'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Menu from '../components/Menu'

const loginFormSchema = z.object({
  identifier: z.string({ required_error: 'This field is required' }).email({ message: 'Please enter an Email' }),
  password: z.string({ required_error: 'This field is requried' })
});

const Login = () => {
  const navigate = useNavigate()

  const { authenticated, login } = useContext(Context)

  const [formValues, setFormValues] = useState<any>({})
  const [validation, setValidation] = useState<any>()
  const [loginFailed, setLoginFailed] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>()

  useEffect(() => {
    if (authenticated) {
      navigate('/dashboard')
    }
  }, [authenticated, navigate])

  const handleSubmitForm = async (e: React.MouseEvent) => {
    e.preventDefault()

    if (loading) return

    setLoading(true)
  
    try {
      loginFormSchema.parse(formValues);

      const user = await login(formValues)

      if (user) navigate('/dashboard')
    } catch (error: any) {
      if (error instanceof ZodError) {
        setValidation(error.formErrors.fieldErrors);
        return;
      }

      if (error.message === 'Legacy') {
        navigate('/password/lost', { state: { isLegacy: true } })
      }

      setLoginFailed(true)
    } finally {
      setLoading(false)
    }
  }

  const handleFormChange = (event: any) => {
    setFormValues((previousState: any) => ({ ...previousState, [event.target.name]: event.target.value }))
  }

  return (
    <>
      <div className='bg-white'>
        <Menu />
        <Header />
        <div className='flex flex-col items-center py-12 lg:py-24'>
          <div className='flex flex-col justify-evenly max-w-4xl px-1.5 lg:flex-row'>
            <form className='mx-1'>
              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:flex-right'>
                  <label className='font-semibold w-20 mr-2 lg:text-right'>Email</label>
                  <input
                    onChange={handleFormChange}
                    name='identifier'
                    id='identifier'
                    className='w-full lg:w-80'
                    type='email'
                  ></input>
                </div>
                {validation?.identifier && <p className='mt-1 lg:pl-24 text-red-600'>{validation?.identifier}</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2 lg:text-right'>Password</label>
                  <input
                    onChange={handleFormChange}
                    name='password'
                    className='w-full lg:w-80'
                    id='password'
                    type='password'
                  ></input>
                </div>
                {validation?.password && <p className='mt-1 lg:pl-24 text-red-600'>{validation?.password}</p>}
              </div>

              <div className='flex flex-col justify-between mt-4 lg:flex-row'>
                <Link to='/password/lost' className='block font-semibold underline lg:pl-24'>
                  Lost Your Password?
                </Link>
                <button
                  disabled={loading}
                  onClick={handleSubmitForm}
                  type='submit'
                  className={`button font-semibold w-24 mt-4 lg:mt-0 ${loading ? 'opacity-70 cursor-default' : ''}`}
                >
                  Log In
                </button>
              </div>
              {loginFailed && (
                <p className='mt-2 text-red-600 font-bold lg:pl-24'>These credentials do not match our records.</p>
              )}
              {loading && <p className='mt-2 text-lg font-bold'>Loggin in...</p>}
            </form>

            <div className='mx-1 lg:ml-14'>
              <p className='my-0 font-semibold text-xl'>Not registered?*</p>
              <p className='font-semibold mt-2'>
                Complete our{' '}
                <Link className='underline' to='/register'>
                  signup form
                </Link>{' '}
                to request access.
              </p>
              <p className='font-semibold mt-2'>
                *If you were already registered on our old site you’ll need to{' '}
                <Link className='underline' to='/register'>
                  re-register
                </Link>
                .
              </p>
            </div>
          </div>

          <div className='mt-8 text-center '>
            <a className='font-semibold underline' href='mailto:Investorrelations@loancore.com'>
              Contact Us
            </a>
          </div>

          <div className='max-w-4xl px-2 mt-8 text-sm font-semibold leading-6'>
            <p>
              For current holders, prospective purchasers, security analysts, and market makers who would like access to
              the Investor Center but have not yet been granted access by LoanCore Capital Markets LLC ("LCM"), complete
              the following Certification Form. The completed form will sent electronically to Tyler Shea, Chief
              Accounting Officer. Eligible persons who submit a completed certification form will receive an email with
              instructions for accessing the Investor Center.
            </p>
            <p>
              For returning visitors, by entering the Investor Center you are certifying that you are one of the parties
              described in (i)-(v) under Financial Reports below and that you will notify LCM promptly of any change in
              your status.
            </p>
            <p className='font-bold mt-8'>Financial Reports</p>
            <p>
              LCM is privately held and does not make its financial information available to the public. However, this
              information is provided on a confidential basis to: (i) LCM members; (ii) holders of the Senior Notes due
              2020 (the “Notes”) issued by LCM and its wholly-owned subsidiary, LCM Finance Corporation; (iii) certain
              prospective investors in the Notes; (iv) security analysts who cover or intend to cover LCM and the Notes;
              and (v) market makers who regularly make or intend to make a market in the Notes via a secured information
              center (the “Investor Center”) on which LCM provides annual and quarterly reports and certain other
              information relating to its business.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Login
