import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import Context from '../Context'

import Announcements from '../components/Announcements'
import Documents from '../components/Documents'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Menu from '../components/Menu'

enum MenuOptions {
  Announcements,
  FinancialDocuments,
}

const Dashboard = () => {
  const navigate = useNavigate()

  const { authenticated } = useContext(Context)

  const [selectedMenuOption, setSelectedMenuOption] = useState<MenuOptions>(MenuOptions.Announcements)

  useEffect(() => {
    if (!authenticated) {
      navigate('/login')
    }
  }, [authenticated, navigate])

  return (
    <>
      <div className='bg-white'>
        <Menu />
        <Header />
        {authenticated && (
          <>
            <div className='flex justify-center pt-12 pb-6 lg:pt-24'>
              <div
                className={`mx-4 font-bold text-base md:text-2xl pointer underline-offset hover:underline ${
                  selectedMenuOption === MenuOptions.Announcements ? 'underline' : ''
                }`}
                onClick={() => setSelectedMenuOption(MenuOptions.Announcements)}
              >
                ANNOUNCEMENTS
              </div>
              <div
                className={`mx-4 font-bold text-base md:text-2xl pointer underline-offset hover:underline ${
                  selectedMenuOption === MenuOptions.FinancialDocuments ? 'underline' : ''
                }`}
                onClick={() => setSelectedMenuOption(MenuOptions.FinancialDocuments)}
              >
                FINANCIAL DOCUMENTS
              </div>
            </div>
            {selectedMenuOption === MenuOptions.Announcements && <Announcements />}
            {selectedMenuOption === MenuOptions.FinancialDocuments && <Documents />}
          </>
        )}
      </div>
      <Footer />
    </>
  )
}

export default Dashboard
