import loancoreLogo from '../images/loancore-logo.svg'

const Footer = () => {
  return (
    <footer className='flex justify-center'>
      <div className='flex flex-col max-w-6xl w-full font-semibold'>
        <a href='https://lcm.loancore.com'>
          <img className='logo' src={loancoreLogo} alt='LoanCore Logo' />
        </a>
        <p className='mt-4'>Investor Relations</p>
        <p className='mt-px'>Loancore Capital</p>
        <p className='mt-4 text-base'>Copyright &#169; LoanCore Capital</p>
        <p className='mt-2 text-base'>Loans will be arranged or made pursuant to a California Financing Law License</p>
      </div>
    </footer>
  )
}

export default Footer
