import loancoreLogo from '../images/loancore-logo.svg'

const Header = () => {
  return (
    <header className='header'>
      <div className='content max-w-6xl'>
        <a href='https://lcm.loancore.com' className=''>
          <img className='logo' src={loancoreLogo} alt='LoanCore Logo' />
        </a>
        <h1>Investor Relations</h1>
      </div>
    </header>
  )
}

export default Header
