import Menu from '../components/Menu'

import loancoreLogo from '../images/loancore-logo.svg'

const Home = () => {
  return (
    <>
      <Menu />
      <div className='home-container'>
        <div className='markets-logo'>
          <img src={loancoreLogo} alt='LoanCore Capital Markets' />
        </div>
      </div>
    </>
  )
}

export default Home
