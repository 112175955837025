import { useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import api from '../services/api'

import Menu from '../components/Menu'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ResetPassword = () => {
  const navigate = useNavigate()

  const [resetCode, setResetCode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<any>({})
  const [validation, setValidation] = useState<any>({})
  const [resetFailed, setResetFailed] = useState<boolean>(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const resetCode = urlParams.get('code')
    if (resetCode) {
      setResetCode(resetCode)
    }
  }, [resetCode, setResetCode])

  const passwordsMatch = useMemo(() => {
    return formValues.password === formValues.confirmPassword
  }, [formValues.password, formValues.confirmPassword])

  const handleFormChange = (event: any) => {
    setFormValues((previousState: any) => ({ ...previousState, [event.target.name]: event.target.value }))
  }

  const handleSubmitForm = async (e: React.MouseEvent) => {
    e.preventDefault()

    if (!resetCode) {
      setLoading(false)
      setResetFailed(true)
      return
    }

    setLoading(true)

    const validation = {
      password: formValues.password.trim() === '',
      confirmPassword: formValues.password.trim() === '',
    }

    setValidation(validation)
    if (Object.values(validation).some((value) => value === true)) {
      setLoading(false)
      return
    }

    try {
      await api.resetPassword({
        code: resetCode,
        password: formValues.password,
        passwordConfirmation: formValues.confirmPassword,
      })

      setResetFailed(false)
      navigate('/login')
    } catch (error: any) {
      setResetFailed(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Menu />
      <Header />
      <div className='flex flex-col items-center bg-white p-12 md:pb-40'>
        <form className='mx-1 max-w-2xl w-full'>
          <p className='pb-8 text-center'>Use this form to set a password for your account and login.</p>

          <div className='flex flex-col mb-4'>
            <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
              <label className='font-semibold w-20 mr-2'>New Password</label>
              <input onChange={handleFormChange} type='password' name='password'></input>
            </div>
            {validation.password && <p className='lg:pl-24 text-red-600'>This field is required</p>}
          </div>

          <div className='flex flex-col mb-4'>
            <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
              <label className='font-semibold w-20 mr-2'>Confirm Password</label>
              <input onChange={handleFormChange} type='password' name='confirmPassword'></input>
            </div>
            {validation.confirmPassword && <p className='lg:pl-24 text-red-600'>This field is required</p>}
            {!passwordsMatch && <p className='lg:pl-24 text-red-600'>Passwords don't match</p>}
            <div className='flex flex-col justify-end mt-4 lg:flex-row'>
              <button
                onClick={handleSubmitForm}
                disabled={loading}
                className={`button font-semibold w-40 mt-4 lg:mt-0 ${loading ? 'opacity-70 cursor-default' : ''}`}
              >
                Save and Log In
              </button>
            </div>
            {resetFailed && (
              <p className='mt-2 text-red-600 font-bold lg:pl-24'>Unable to reset your password at this time.</p>
            )}
          </div>
        </form>
        <div className='mt-8 text-center '>
          <a className='font-semibold underline' href='mailto:Investorrelations@loancore.com'>
            Contact Us
          </a>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ResetPassword
