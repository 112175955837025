import { useMemo, useState } from 'react'
import { z, ZodError } from 'zod';

import api from '../services/api'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

const registerFormSchema = z.object({
  name: z.string({ required_error: 'This field is requried' }),
  email: z.string({ required_error: 'This field is required' }).email({ message: 'Please enter an Email' }),
  password: z.string({ required_error: 'This field is requried' }),
  confirmPassword: z.string({ required_error: 'This field is requried' }),
  company: z.string({ required_error: 'This field is requried' }),
  title: z.string({ required_error: 'This field is requried' }),
  address: z.string({ required_error: 'This field is requried' }),
  city: z.string({ required_error: 'This field is requried' }),
  state: z.string({ required_error: 'This field is requried' }),
  zip: z.string({ required_error: 'This field is requried' }),
  phone: z.string({ required_error: 'This field is requried' }),
  amountHeld: z.string({ required_error: 'This field is requried' }),
  jobTitle: z.string({ required_error: 'This field is requried' }),
});

const Register = () => {
  const [formValues, setFormValues] = useState<any>({})
  const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false)
  const [validation, setValidation] = useState<any>({})
  const [loading, setLoading] = useState<boolean>()
  const [emailTakenError, setEmailTakenError] = useState<boolean>()
  const [genericError, setUnknownError] = useState<boolean>()

  const passwordsMatch = useMemo(() => {
    return formValues.password === formValues.confirmPassword
  }, [formValues.password, formValues.confirmPassword])

  const handleFormChange = (event: any) => {
    setFormValues((previousState: any) => ({ ...previousState, [event.target.name]: event.target.value }))
  }

  const handleSubmitForm = async (e: React.MouseEvent) => {
    e.preventDefault()

    if (loading) return

    setLoading(true)

    try {
      registerFormSchema.parse(formValues);

      await api.register(formValues)
      setRegistrationSuccessful(true)
    } catch (error: any) {
      if (error instanceof ZodError) {
        setValidation(error.formErrors.fieldErrors);
        return;
      }

      setValidation({});

      if (error.message === 'Auth.form.error.email.taken') {
        setEmailTakenError(true)
      } else {
        setUnknownError(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Menu />
      <Header />
      <div className='flex flex-col items-center bg-white p-12'>
        {registrationSuccessful && (
          <div className='py-24'>
            Thank you. Your registration was successful. You will recieve an email if your account is approved.
          </div>
        )}
        {!registrationSuccessful && (
          <>
            <form className='mx-1 max-w-2xl w-full'>
              <p className='pb-8 text-center'>All fields are required</p>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Name</label>
                  <input name='name' onChange={handleFormChange}></input>
                </div>
                {validation.name && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Email</label>
                  <input name='email' onChange={handleFormChange} type='email' autoComplete='username'></input>
                </div>
                {validation.emailValid && !validation.email && (
                  <p className='lg:pl-24 text-red-600'>Please enter a valid email</p>
                )}
                {validation.email && <p className='lg:pl-24 text-red-600'>{validation.email}</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Password</label>
                  <input
                    name='password'
                    onChange={handleFormChange}
                    type='password'
                    autoComplete='new-password'
                  ></input>
                </div>
                {validation.password && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Confirm Password</label>
                  <input
                    name='confirmPassword'
                    onChange={handleFormChange}
                    type='password'
                    autoComplete='new-password'
                  ></input>
                </div>
                {!passwordsMatch && <p className='lg:pl-24 text-red-600'>Passwords don't match</p>}
                {validation.confirmPassword && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Company</label>
                  <input name='company' onChange={handleFormChange}></input>
                </div>
                {validation.company && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Title</label>
                  <input name='title' onChange={handleFormChange}></input>
                </div>
                {validation.title && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Address</label>
                  <input name='address' onChange={handleFormChange}></input>
                </div>
                {validation.address && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>City</label>
                  <input name='city' onChange={handleFormChange}></input>
                </div>
                {validation.city && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>State</label>
                  <select onChange={handleFormChange} name='state' defaultValue=''>
                    <option disabled value=''></option>
                    <option value='AL'>Alabama</option>
                    <option value='AK'>Alaska</option>
                    <option value='AZ'>Arizona</option>
                    <option value='AR'>Arkansas</option>
                    <option value='CA'>California</option>
                    <option value='CO'>Colorado</option>
                    <option value='CT'>Connecticut</option>
                    <option value='DE'>Delaware</option>
                    <option value='FL'>Florida</option>
                    <option value='GA'>Georgia</option>
                    <option value='HI'>Hawaii</option>
                    <option value='ID'>Idaho</option>
                    <option value='IL'>Illinois</option>
                    <option value='IN'>Indiana</option>
                    <option value='IA'>Iowa</option>
                    <option value='KS'>Kansas</option>
                    <option value='KY'>Kentucky</option>
                    <option value='LA'>Louisiana</option>
                    <option value='ME'>Maine</option>
                    <option value='MD'>Maryland</option>
                    <option value='MA'>Massachusetts</option>
                    <option value='MI'>Michigan</option>
                    <option value='MN'>Minnesota</option>
                    <option value='MS'>Mississippi</option>
                    <option value='MO'>Missouri</option>
                    <option value='MT'>Montana</option>
                    <option value='NE'>Nebraska</option>
                    <option value='NV'>Nevada</option>
                    <option value='NH'>New Hampshire</option>
                    <option value='NJ'>New Jersey</option>
                    <option value='NM'>New Mexico</option>
                    <option value='NY'>New York</option>
                    <option value='NC'>North Carolina</option>
                    <option value='ND'>North Dakota</option>
                    <option value='OH'>Ohio</option>
                    <option value='OK'>Oklahoma</option>
                    <option value='OR'>Oregon</option>
                    <option value='PA'>Pennsylvania</option>
                    <option value='RI'>Rhode Island</option>
                    <option value='SC'>South Carolina</option>
                    <option value='SD'>South Dakota</option>
                    <option value='TN'>Tennessee</option>
                    <option value='TX'>Texas</option>
                    <option value='UT'>Utah</option>
                    <option value='VT'>Vermont</option>
                    <option value='VA'>Virginia</option>
                    <option value='WA'>Washington</option>
                    <option value='WV'>West Virginia</option>
                    <option value='WI'>Wisconsin</option>
                    <option value='WY'>Wyoming</option>
                  </select>
                </div>
                {validation.state && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Postal/Zip</label>
                  <input name='zip' onChange={handleFormChange}></input>
                </div>
                {validation.zip && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Phone</label>
                  <input name='phone' onChange={handleFormChange}></input>
                </div>
                {validation.phone && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>Amount Held</label>
                  <input name='amountHeld' onChange={handleFormChange}></input>
                </div>
                {validation.amountHeld && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col mb-4'>
                <div className='flex flex-col lg:flex-row lg:items-center lg:text-right'>
                  <label className='font-semibold w-20 mr-2'>I am a...</label>
                  <select onChange={handleFormChange} name='jobTitle' defaultValue=''>
                    <option disabled value=''></option>
                    <option value='BeneficialOwner'>Beneficial Owner</option>
                    <option value='QIB'>QIB</option>
                    <option value='Lender'>Lender</option>
                    <option value='Broker'>Broker</option>
                    <option value='SecuritiesAnalyst'>Securities Analyst</option>
                  </select>
                </div>
                {validation.jobTitle && <p className='lg:pl-24 text-red-600'>This field is required</p>}
              </div>

              <div className='flex flex-col justify-end mt-4 lg:flex-row'>
                <button
                  onClick={handleSubmitForm}
                  type='submit'
                  className={`button font-semibold w-24 mt-4 lg:mt-0 ${loading ? 'opacity-70 cursor-default' : ''}`}
                >
                  Register
                </button>
              </div>
              {loading && <p className='mt-2 text-lg font-bold'>Registering...</p>}
              {emailTakenError && <p className='mt-2 lg:pl-24 text-red-600'>That email has already been taken.</p>}
              {genericError && <p className='mt-2 lg:pl-24 text-red-600'>Unable to register at this time.</p>}
              <div className='mt-8 text-center '>
                <a className='font-semibold underline' href='mailto:Investorrelations@loancore.com'>
                  Contact Us
                </a>
              </div>
            </form>

            <div className='max-w-4xl px-2 mt-8 text-sm font-semibold leading-6'>
              <p className='mb-8'>
                The undersigned hereby represents and warrants to LoanCore Capital Markets LLC (the "Company") as
                follows (please check boxes for those items that apply to you):
              </p>
              <ul className='mb-8 pl-6'>
                <li className='list-disc'>
                  It is the beneficial owner, or is acting on behalf of a beneficial owner, of the Issuers’ 6.875%
                  Senior Notes due 2020 (the "Notes") in the amount set forth below.zz
                </li>
                <li className='list-disc'>
                  It is (i) a "qualified institutional buyer" (or "QIB") within the meaning of Rule 144A under the U.S.
                  Securities Act of 1933, as amended (the "Securities Act"), (ii) (a) not a "U.S. person" as
                  contemplated by Rule 903(a)(1) of Regulation S under the Securities Act or (b) a dealer or other
                  professional fiduciary organized, incorporated or (if an individual) resident in the U.S. holding a
                  discretionary account or similar account (other than an estate or trust) for the benefit or account of
                  a non-U.S. person, as contemplated by Rule 903(a)(1) of Regulation S under the Securities Act or (iii)
                  an accredited investor as defined in Rule 501(a)(1), (2), (3), or (7) under the Securities Act (any
                  such person satisfying the description of (i), (ii) or (iii), a "Qualifying Prospective Purchaser"),
                  and, in the case of each of (i), (ii) and (iii), is considering acquiring any of the Notes for its own
                  account or for a discretionary account or accounts on behalf of one or more Qualifying Prospective
                  Purchasers (as to which it has been instructed and has the authority to make the statements contained
                  herein).
                </li>
                <li className='list-disc'>
                  It is a reputable securities analyst who regularly covers or intends to cover the Issuers and the
                  Notes, or is a reputable market maker who regularly makes or intends to make a market in the Notes.
                </li>
                <li className='list-disc'>
                  It is a broker or dealer registered under the Securities Exchange Act of 1934, as amended.
                </li>
                <li className='list-disc'>
                  It is a lender or administrative agent for any financing arrangements that either of the Issuers are a
                  party to
                </li>
              </ul>
              <p className='mb-8'>
                The undersigned understands that it is providing the information contained herein solely for purposes of
                enabling the Issuers to determine whether the undersigned should receive access to the password
                protected online data system (the "Secured System") on which the Issuers provides annual and quarterly
                reports and certain other information relating to their business, in accordance with the indenture
                relating to the Notes.
              </p>
              <p className='mb-8'>
                The undersigned also understands that the Issuers expressly reserve the right to deny or revoke access
                to any person at their discretion if the Issuers are not satisfied that such person meets the stated
                requirements.
              </p>
              <p className='mb-8'>
                The undersigned represents and warrants that it is not a competitor of the Issuers or a person working
                on behalf of, or advising, a competitor of the Issuers and is not seeking access to the Secured System
                for the purpose of obtaining information in order to compete with the Issuers.
              </p>
              <p className='mb-8'>
                The undersigned agrees that (1) it will not copy, reproduce or retransmit any part of the documents
                contained on the Secured System or transmitted through the Issuers’ periodic conference calls, (2) it
                will not distribute or transmit any part of such materials or disclose any of their contents to anyone
                other than, if applicable, the aforementioned beneficial owners on whose behalf the undersigned is
                acting and (3) it will notify the Issuers if any of the representations it makes in this letter cease to
                be correct.
              </p>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  )
}

export default Register
