import { useCallback, useContext, useEffect, useState, SyntheticEvent } from 'react'

import Context from '../Context'
import Api from '../services/api'

import pdfIcon from '../images/icon-pdf.png'

const Documents = () => {
  const { token } = useContext(Context)

  const [documents, setDocuments] = useState<Array<JSX.Element>>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getDocument = useCallback(async (event: SyntheticEvent, file: any) => {
    event.preventDefault()

    const blob = await Api.getDocument(file.url, token)

    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = file.name
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();  //afterwards we remove the element again         
  }, [token])

  const handleGetDocuments = useCallback(async () => {
    setLoading(true)
    try {
      if (!token) throw new Error('No authentication token!')

      const nextDocuments: Array<JSX.Element> = []
      const documentsResponse = await Api.getDocuments(token)

      documentsResponse.forEach((doc: any, i: number) => {
        nextDocuments.push(
          <div key={doc.id} className={`flex items-center w-full p-8 ${i % 2 === 0 ? `bg-gray-200` : `bg-white`}`}>
            <div className='w-36'>{doc.date.toDateString()}</div>
            <img className='w-6 mx-4' src={pdfIcon} alt='PDF Icon' />
            <a onClick={(event) => getDocument(event, doc.file.data.attributes)} className='hover:underline pointer'>
              {doc.title}
            </a>
          </div>
        )
      })

      setDocuments(nextDocuments)
    } catch (error) {
      console.error('An error occured fetching documents')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [token, getDocument])

  useEffect(() => {
    handleGetDocuments()
  }, [handleGetDocuments])

  return (
    <div className='flex flex-col items-center'>
      {loading && <div className='text-center mt-4'>Loading...</div>}
      <div className='flex flex-col items-center max-w-4xl w-full mx-2 py-8 text-lg font-bold'>{documents}</div>
    </div>
  )
}

export default Documents
