import { Announcement, Document, ForgotPasswordRequest, LoginRequest, LoginResponse, RegisterRequest, ResetPasswordRequest } from '../types/types'

const apiBase = process.env.REACT_APP_API_ROOT

const login = async (loginRequest: LoginRequest): Promise<LoginResponse> => {
  const payload = {
    identifier: loginRequest.identifier,
    password: loginRequest.password,
  }

  const response = await fetch(`${apiBase}/api/auth/local?populate=*`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    const payload = await response.json()
    throw new Error(payload.error.message)
  }

  return await response.json()
}

const register = async (registerRequest: RegisterRequest): Promise<void> => {
  try {
  registerRequest.username = registerRequest.email

  const response = await fetch(`${apiBase}/api/auth/local/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(registerRequest),
  })

  if (!response.ok) {
    throw new Error('Error registering.')
  }
  } catch (error) {
    console.error(error);
    throw new Error('Error registering');
  }
}

const forgotPassword = async (forgotPasswordRequest: ForgotPasswordRequest): Promise<void> => {
  const response = await fetch(`${apiBase}/api/auth/forgot-password`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ email: forgotPasswordRequest.email }),
  })

  if (!response.ok) {
    throw new Error('Error sending forgot password email.')
  }
}

const resetPassword = async (resetPasswordRequest: ResetPasswordRequest): Promise<void> => {
  const response = await fetch(`${apiBase}/api/auth/reset-password`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      code: resetPasswordRequest.code,
      password: resetPasswordRequest.password,
      passwordConfirmation: resetPasswordRequest.passwordConfirmation,
    }),
  })

  if (!response.ok) {
    throw new Error('Error resetting password')
  }
}

const getAnnouncements = async (token: string): Promise<Array<Announcement>> => {
  const response = await fetch(`${apiBase}/api/announcements`, {
    headers: { Authorization: `Bearer ${token}` },
  })

  if (!response.ok) {
    throw new Error('Error fetching announcements.')
  }

  const announcements = await response.json()

  return announcements.data
    .map((a: any) => ({
      ...a.attributes,
      date: new Date(a.attributes.date)
    }))
    .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
}

const getDocuments = async (token: string): Promise<Array<Document>> => {
  const response = await fetch(`${apiBase}/api/documents?populate=*&pagination[pageSize]=100`, {
    headers: { Authorization: `Bearer ${token}` },
  })

  if (!response.ok) {
    throw new Error('Error fetching documents.')
  }

  const documents = await response.json()

  return documents.data
    .map((a: any) => ({
      ...a.attributes,
      id: a.id,
      date: new Date(a.attributes.date),
      url: a.attributes.file.data.attributes.url
    }))
    .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
}

const getDocument = async (fileUrl: string, token: string): Promise<Blob> => {
  const response = await fetch(`${apiBase}${fileUrl}`, {
    method: 'GET',
    headers: new Headers({
      'Authorization': `Bearer ${token}`
    })
  })

  if (!response.ok) {
    throw new Error('Error fetching document')
  }

  return await response.blob()
}

const api = {
  apiBase,
  login,
  register,
  forgotPassword,
  getAnnouncements,
  resetPassword,
  getDocuments,
  getDocument
}

export default api
